import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
  width: 100%;
  background: #f5f5f5;
  padding: 1rem 0 1rem 0;
  display: grid;
  justify-items: center;
  justify-content: center;
  grid-gap: 1rem;
`

const Footer = () => {
  const style = {
    fill: "#999999",
  }
  return (
    <StyledFooter>
      {/* <div>
        Icons made by{" "}
        <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
          Freepik
        </a>
      </div> */}
      <svg width="62px" height="62px" viewBox="0 0 105.144 105.144">
        <g>
          <path
            style={style}
            d="M98.126,30.812c-9.885-11.406-33.412-10.611-35.012-1.417c-1.328,7.659,6.71,11.943,10.554,15.915
		c4.445,4.592,5.2,12.011,1.535,17.071c-1.528,2.101-10.651,11.331-7.938,20.446c1.378,4.624,4.481,6.696,8.539,7.537
		c13.496,2.809,14.92-7.899,15.06-12.651c0.125-4.459,0.791-8.793,2.627-12.877C97.997,54.59,104.829,38.55,98.126,30.812z"
          />
          <path
            style={style}
            d="M63.991,0.045c3.053-0.49,6.166,3.114,6.957,8.045c0.791,4.932-1.034,9.33-4.083,9.82
		c-3.053,0.49-6.163-3.114-6.957-8.045C59.12,4.933,60.945,0.535,63.991,0.045z M81.828,9.371c0.186,3.454-1.389,6.345-3.522,6.463
		c-2.137,0.115-4.015-2.591-4.205-6.045s1.392-6.345,3.522-6.463C79.756,3.212,81.638,5.918,81.828,9.371z M90.789,11.862
		c0.154,2.802-1.127,5.154-2.863,5.247c-1.736,0.093-3.257-2.101-3.411-4.907c-0.154-2.806,1.124-5.154,2.86-5.25
		C89.111,6.855,90.639,9.056,90.789,11.862z M96.315,16.006c-0.147,2.072-1.306,3.679-2.588,3.586
		c-1.281-0.089-2.201-1.843-2.054-3.915s1.303-3.679,2.588-3.586C95.538,12.181,96.462,13.934,96.315,16.006z M100.23,20.895
		c-0.115,1.653-1.041,2.935-2.065,2.863c-1.02-0.075-1.757-1.474-1.643-3.128c0.118-1.653,1.045-2.935,2.069-2.863
		C99.615,17.842,100.348,19.245,100.23,20.895z"
          />
          <path
            style={style}
            d="M42.027,43.71c-1.6-9.194-25.127-9.985-35.008,1.417c-6.703,7.737,0.122,23.781,4.635,34.024
		c1.836,4.087,2.498,8.414,2.627,12.873c0.14,4.753,1.568,15.461,15.06,12.658c4.058-0.845,7.161-2.913,8.536-7.541
		c2.713-9.115-6.406-18.345-7.934-20.446c-3.668-5.06-2.913-12.476,1.532-17.071C35.317,55.653,43.355,51.369,42.027,43.71z"
          />
          <path
            style={style}
            d="M34.186,22.409c0.795-4.932,3.908-8.536,6.954-8.045c3.046,0.49,4.874,4.885,4.083,9.82
		c-0.795,4.932-3.908,8.536-6.954,8.045C35.22,31.739,33.395,27.344,34.186,22.409z M31.047,24.102
		c-0.186,3.454-2.072,6.159-4.205,6.041c-2.137-0.115-3.715-3.01-3.525-6.463c0.19-3.454,2.072-6.159,4.205-6.041
		C29.655,17.756,31.237,20.652,31.047,24.102z M20.626,26.521c-0.154,2.806-1.678,5-3.411,4.907
		c-1.729-0.093-3.01-2.444-2.856-5.247c0.15-2.806,1.678-5.003,3.411-4.91C19.499,21.367,20.776,23.719,20.626,26.521z
		 M13.468,29.992c0.147,2.072-0.773,3.826-2.051,3.915s-2.437-1.517-2.584-3.59s0.773-3.826,2.051-3.915
		C12.165,26.313,13.321,27.92,13.468,29.992z M8.608,34.953c0.118,1.653-0.616,3.056-1.639,3.128
		c-1.02,0.072-1.943-1.21-2.061-2.863c-0.118-1.653,0.616-3.056,1.636-3.128C7.567,32.015,8.49,33.296,8.608,34.953z"
          />
        </g>
      </svg>
      <span>© {new Date().getFullYear()} Prendiville Ave Podiatry</span>
    </StyledFooter>
  )
}

export default Footer
