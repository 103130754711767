import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { primaryColor, secondaryColor } from "../../site-config"

const StyledNavbarLink = styled(Link)`
  color: ${primaryColor};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${secondaryColor};
  }
`

const Navlink = ({ to, children }) => {
  return (
    <StyledNavbarLink to={to} activeStyle={{ color: secondaryColor }}>
      {children}
    </StyledNavbarLink>
  )
}

Navlink.propTypes = {
  to: PropTypes.string.isRequired,
}

export default Navlink
