import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { MobileFooter, Navbar } from "@hemalr/react-blocks"
import { mobileBreakpoint, email, phone, mapLink } from "../../site-config"
import Navlink from "../styled-components/navlink"
import Logo from "./logo"
import Footer from "./footer"
import BookButton from "./book-button"
import "./layout.css"

const StyledApp = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  @media (max-width: ${mobileBreakpoint}) {
    & {
      margin-bottom: 4rem;
      min-height: calc(100vh - 4rem);
    }
  }
`

const Layout = ({ children }) => {
  return (
    <StyledApp>
      <Navbar
        breakpoint={mobileBreakpoint}
        logo={
          <Link to="/">
            <Logo />
          </Link>
        }
      >
        <Navlink to="/">HOME</Navlink>
        <Navlink to="/our-podiatrists">OUR PODIATRISTS</Navlink>
        <Navlink to="/services">SERVICES</Navlink>
        {/* <Navlink to="/feet-academy">FEET ACADEMY</Navlink> */}
        {/* <Navlink to="/pricing">PRICING</Navlink> */}
        <Navlink to="/contact-us">CONTACT US</Navlink>
        <BookButton text="BOOK APPOINTMENT" />
      </Navbar>
      <div>
        <main>{children}</main>
      </div>
      <Footer />
      <MobileFooter mapLink={mapLink} email={email} phone={phone} />
    </StyledApp>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
