import React from "react"
import { Link } from "gatsby"
import { Button } from "@hemalr/react-blocks"
import styled from "styled-components"
import { primaryColor } from "../../site-config"

const StyledBookButton = styled.div`
  display: grid;
  justify-items: center;
`

const BookButton = ({ text = "BOOK APPOINTMENT", size = "1rem" }) => {
  return (
    <StyledBookButton>
      <a href="tel:(08) 9300 8800">
        <Button
          text={text}
          size={size}
          color={primaryColor}
          style={{ width: "auto" }}
        />
      </a>
    </StyledBookButton>
  )
}

export default BookButton
