import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { mobileBreakpoint } from "../../site-config"

const StyledLogo = styled(Img)`
  height: 100px;
  width: 100px;
  @media (max-width: ${mobileBreakpoint}) {
    & {
      height: 80px;
      width: 80px;
    }
  }
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "perth-foot-medic-logo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledLogo
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Perth Foot Medic Logo"
    />
  )
}

export default Logo
