module.exports = {
  name: `mopodiatry`,
  primaryColor: "#618AAC",
  secondaryColor: "#97B5CF",
  mapLink: "https://goo.gl/maps/CcnQqF6xrNhu42zb9",
  address: `Unit 1, 4 Prendiville Ave,
Ocean Reef,
WA 6027`,
  mapEmbed:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13570.582966327178!2d115.7398997!3d-31.7528693!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x373157eda10433f6!2sPrendiville%20Ave%20Podiatry!5e0!3m2!1sen!2sau!4v1572054463798!5m2!1sen!2sau",
  phone: "(08) 9300 8800",
  email: "hello@mopodiatry.com.au",
  siteMetadata: {
    title: `Prendiville Ave Podiatry`,
    description: `Prendiville Ave Podiatry - Leading podiatrist in Perth's Northern Suburbs (Ocean Reef, Joondalup). Preventative measures and treatments of all foot related issues`,
    author: `Hemal Ruparelia`,
    siteUrl: "https://www.mopodiatry.com.au",
  },
  icon: `src/images/perth-foot-medic-logo.png`,
  mobileBreakpoint: "945px",
}
